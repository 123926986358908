import React, { useContext, useEffect, useState } from 'react'
import appContext from '../context'
import Slider from 'react-slick'
import Pagination from './pagination'

const settings = {
  arrows: true,
  infinite: false,
  variableWidth: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const Results = () => {
  const context = useContext(appContext)
  const [page, setPage] = useState(context.filters.page)
  const [cards, setCards] = useState([])

  // sets up cards array and splices in featured / event cards where needed
  useEffect(() => {
    setCards([])

    if (context.filters.type === 'Events' && context.results.events) {
      // show only event cards
      const eventCards = [...context.results.events]

      let guideSpliceId = 7

      eventCards.every((card, index) => {
        if (index > guideSpliceId) {
          return false
        } else {
          if (card.featured) {
            guideSpliceId -= 1
          }

          return true
        }
      })

      if ((context.results.guides || {}).pages) {
        eventCards.splice(guideSpliceId, 0, context.results.guides)
      }

      setCards(eventCards)
    } else if (context.filters.type === 'Businesses') {
      // show only business cards
      const businessCards = [...context.results.businesses]

      if (context.results.featured) {
        const featuredCards = [...context.results.featured]

        featuredCards.forEach((item, index) => {
          if (businessCards.length > 0 && index === 0) {
            businessCards.splice(1, 0, item)
          } else if (businessCards.length > 8 && index === 1) {
            businessCards.splice(9, 0, item)
          } else if (businessCards.length > 9 && index === 2) {
            businessCards.splice(10, 0, item)
          } else {
            businessCards.push(item)
          }
        })
      }

      let guideSpliceId = 6

      businessCards.every((card, index) => {
        if (index > guideSpliceId) {
          return false
        } else {
          if (card.featured) {
            guideSpliceId -= 1
          }

          return true
        }
      })

      if ((context.results.guides || {}).pages) {
        businessCards.splice(guideSpliceId, 0, context.results.guides)
      }

      setCards(businessCards)
    } else {
      // show all results
      if (context.results.businesses) {
        const businessCards = [...context.results.businesses]

        if (context.results.featured) {
          const featuredCards = [...context.results.featured]

          featuredCards.forEach((item, index) => {
            if (businessCards.length > 0 && index === 0) {
              businessCards.splice(1, 0, item)
            } else if (businessCards.length > 6 && index === 1) {
              businessCards.splice(7, 0, item)
            } else if (businessCards.length > 7 && index === 2) {
              item.alternate = true
              businessCards.splice(8, 0, item)
            } else {
              businessCards.push(item)
            }
          })
        }

        if (context.results.events) {
          const eventCards = [...context.results.events]

          eventCards.forEach((item, index) => {
            if (businessCards.length > 3 && index === 0) {
              businessCards.splice(4, 0, item)
            } else if (businessCards.length > 6 && index === 1) {
              businessCards.splice(7, 0, item)
            } else {
              businessCards.push(item)
            }
          })
        }

        let guideSpliceId = 6

        businessCards.every((card, index) => {
          if (index > guideSpliceId) {
            return false
          } else {
            if (card.featured) {
              guideSpliceId -= 1
            }

            return true
          }
        })

        if ((context.results.guides || {}).pages) {
          businessCards.splice(guideSpliceId, 0, context.results.guides)
        }

        setCards(businessCards)
      }
    }
  }, [context.results])

  useEffect(() => {
    setPage(context.filters.page)
  }, [context.filters.page])

  // pagination control
  const changePage = (newPage) => {
    window.scrollTo(top)

    const updatedFilters = {
      ...context.filters,
      page: newPage,
    }

    context.setFilters(updatedFilters)
  }

  return (
    <div className='explore__results'>
      <div className='explore__results__grid'>
        {cards.length == 0 ? (
          <p className='no-results'>No results found</p>
        ) : null}

        {cards.map((item, index) => (
          <React.Fragment key={`${item.id}-${index}`}>
            {(item.start && !item.featured) ||
            (item.start && context.filters.type !== 'Events') ? (
              // NON-FEATURED EVENTS
              <a href={item.url} className='event-card'>
                <div className='event-card__image'>
                  {item.image ? (
                    <img src={item.image} alt={item.alt} />
                  ) : (
                    <div className='event-card__default-image' />
                  )}
                </div>

                <div className='event-card__details'>
                  <h3>{item.title}</h3>

                  <div className='event-card__date'>
                    <p>{`${item.start}${item.end ? ' - ' + item.end : ''}`}</p>

                    <p>{item.venue}</p>
                  </div>

                  <div className='event-card__excerpt'>
                    {item.excerpt && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.excerpt,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </a>
            ) : item.start &&
              item.featured &&
              context.filters.type === 'Events' ? (
              // FEATURED EVENTS
              <a
                href={item.url}
                className='business-card business-card--featured'
              >
                <div className='business-card__title'>
                  <h6>Featured:</h6>
                  <h3>{item.title}</h3>
                </div>

                <div className='business-card__image'>
                  {item.image ? (
                    <img src={item.image} alt={item.alt} />
                  ) : (
                    <div className='business-card__default-image' />
                  )}
                </div>

                <div className='business-card__details'>
                  <div className='business-card__title'>
                    <h6>Featured:</h6>
                    <h3>{item.title}</h3>
                  </div>

                  <h4 className='business-card__categories business-card__categories--date'>
                    {`${item.start}${item.end ? ' - ' + item.end : ''}`}
                  </h4>

                  <h4 className='business-card__categories'>{item.venue}</h4>

                  <div className='business-card__excerpt'>
                    {item.excerpt && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.excerpt,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </a>
            ) : item.pages ? (
              // FEATURED GUIDES BLOCK
              <div className='featured-guides'>
                <div className='featured-guides__heading'>
                  {item.settings.heading && <h2>{item.settings.heading}</h2>}

                  {item.settings.subheading && (
                    <p>{item.settings.subheading}</p>
                  )}
                </div>

                <div className='featured-guides__pages'>
                  <Slider
                    {...settings}
                    className='featured-guides__pages__slider'
                  >
                    {item.pages.map((page, index) => (
                      <a
                        key={index}
                        href={page.url}
                        className='featured-guides__page'
                      >
                        {page.hero && (
                          <img src={page.hero.image} alt={page.heroalt} />
                        )}

                        <h3>{page.title}</h3>
                      </a>
                    ))}
                  </Slider>
                </div>
              </div>
            ) : (
              // BUSINESSES
              <a
                href={item.url}
                className={`business-card ${
                  item.featured ? 'business-card--featured' : ''
                } ${item.alternate ? 'business-card--alternate' : ''}`}
              >
                <div className='business-card__title'>
                  {item.featured && <h6>Featured:</h6>}
                  <h3>{item.title}</h3>
                </div>

                <div className='business-card__image'>
                  {item.image ? (
                    <img src={item.image} alt={item.alt} />
                  ) : (
                    <div className='business-card__default-image' />
                  )}
                </div>

                <div className='business-card__details'>
                  <div className='business-card__title'>
                    {item.featured && <h6>Featured:</h6>}
                    <h3>{item.title}</h3>
                  </div>

                  {item.categories.length > 0 && (
                    <h4 className='business-card__categories'>
                      {item.categories.map((category, index) => (
                        <React.Fragment key={index}>
                          {index > 0 ? `, ${category}` : category}
                        </React.Fragment>
                      ))}
                    </h4>
                  )}

                  <div className='business-card__excerpt'>
                    {item.excerpt && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.excerpt,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </a>
            )}
          </React.Fragment>
        ))}
      </div>

      {context.results.pageCount > 1 && (
        <div
          className={
            context.results.pageCount > 6
              ? 'explore__pagination explore__pagination--large'
              : 'explore__pagination'
          }
        >
          <Pagination
            className='pagination-bar'
            currentPage={page}
            totalCount={context.results.pageCount}
            pageSize={10}
            onPageChange={(page) => changePage(page)}
          ></Pagination>
        </div>
      )}
    </div>
  )
}

export default Results
