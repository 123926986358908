// Run this example by adding <%= javascript_pack_tag 'explore' %> to the head of your layout file,
// like app/views/layouts/application.html.slim.

import React from 'react'
import ReactDOM from 'react-dom'

import Explore from '../explore'

document.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementById('explore')

  if (root) {
    ReactDOM.render(<Explore />, root)
  }
})
