import React from 'react'
import classnames from 'classnames'
import { usePagination, DOTS } from './usePagination'

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  return (
    <div
      className={classnames('explore__pagination', { [className]: className })}
    >
      <button
        className='page--prev'
        disabled={currentPage === 1}
        onClick={onPrevious}
      >
        <span>previous</span>
      </button>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <span key={'dots-' + index} className='page dots'>
              &#8230;
            </span>
          )
        }

        return (
          <button
            key={pageNumber}
            className={`page ${pageNumber === currentPage ? 'active' : ''}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        )
      })}
      <button
        className='page--next'
        disabled={currentPage === lastPage}
        onClick={onNext}
      >
        <span>next</span>
      </button>
    </div>
  )
}

export default Pagination
