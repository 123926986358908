export const SET_RESULTS = 'SET_RESULTS'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_FILTERS = 'SET_FILTERS'

const setResults = (results, state) => {
  return { ...state, results }
}

const setCategories = (categories, state) => {
  return { ...state, categories }
}

const setFilters = (filters, state) => {
  return { ...state, filters }
}

export const appReducer = (state, action) => {
  switch (action.type) {
    case SET_RESULTS:
      return setResults(action.results, state)
    case SET_CATEGORIES:
      return setCategories(action.categories, state)
    case SET_FILTERS:
      return setFilters(action.filters, state)
    default:
      return state
  }
}
