import React, { useContext, useEffect, useState } from 'react'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import { DateTime } from 'luxon'
import appContext from '../context'
import useDebounce from '../hooks/useDebounce'
import qs from 'qs'
import 'react-dates/lib/css/_datepicker.css'
import search from '../images/icon_search.svg'
import selected from '../images/icon_selected.svg'
import drop from '../images/icon_drop.svg'
import { ReactComponent as DownArrow } from '../images/icon_down_arrow.svg'

const Filters = () => {
  const context = useContext(appContext)

  // stops api call from happening twice on page load
  const [initialized, setInitialized] = useState(false)

  // checks for desired categories or types on page load
  const [urlParams, setUrlParams] = useState(
    location.search ? qs.parse(location.search.replace('?', '')) : null,
  )

  // current filters and selections
  const [filters, setFilters] = useState(context.filters)
  const [query, setQuery] = useState(context.filters.query)
  const [datesLabel, setDatesLabel] = useState('All')

  // required by the date selector
  const [focus, setFocus] = useState({
    start: false,
    end: false,
  })
  const [datesOpen, setDatesOpen] = useState(false)

  // variables for custom date range
  const [customStart, setCustomStart] = useState(null)
  const [customEnd, setCustomEnd] = useState(null)

  // accordion logic for mobile filters
  const [accordions, setAccordions] = useState({
    category: false,
    type: false,
  })

  // to reduce api calls, limit the search box to wait 500ms before each call
  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    context.getCategories()
  }, [])

  useEffect(() => {
    filterChange('query', debouncedQuery)
  }, [debouncedQuery])

  useEffect(() => {
    // preselect options when url params are present
    if (initialized && urlParams) {
      const currentParams = { ...urlParams }

      const initialCategory = context.categories.filter((category) => {
        return category.replace(/\W+/, '-') === currentParams.category
      })
      const initialType = currentParams.type

      if (initialCategory.length) {
        setUrlParams(null)
        filterChange('categories', initialCategory)
      } else if (initialType) {
        setUrlParams(null)
        filterChange('type', initialType)
      } else {
        context.getResults()
      }
    } else if (initialized) {
      // regular api call since there are no url params
      context.getResults()
    }
  }, [context.categories, context.filters])

  // grabbing dates for preset date ranges
  useEffect(() => {
    if (initialized && datesLabel !== 'Custom') {
      setCustomStart(null)
      setCustomEnd(null)

      let newDateStart = null
      let newDateEnd = null

      if (datesLabel === 'This Week') {
        newDateStart = DateTime.now().startOf('week').toISO()
        newDateEnd = DateTime.now().endOf('week').toISO()
      } else if (datesLabel === 'Next Week') {
        newDateStart = DateTime.now().plus({ weeks: 1 }).startOf('week').toISO()
        newDateEnd = DateTime.now().plus({ weeks: 1 }).endOf('week').toISO()
      } else if (datesLabel === 'Next Month') {
        newDateStart = DateTime.now()
          .plus({ months: 1 })
          .startOf('month')
          .toISO()
        newDateEnd = DateTime.now().plus({ months: 1 }).endOf('month').toISO()
      } else if (datesLabel === 'Next Three Months') {
        newDateStart = DateTime.now()
          .plus({ months: 1 })
          .startOf('month')
          .toISO()
        newDateEnd = DateTime.now().plus({ months: 3 }).endOf('month').toISO()
      }

      const updatedFilters = {
        ...filters,
        dateStart: newDateStart,
        dateEnd: newDateEnd,
      }

      setFilters(updatedFilters)
      context.setFilters(updatedFilters)
    }
  }, [datesLabel])

  const categorySelect = (category) => {
    const updatedFilters = {
      ...filters,
      ['type']: 'Businesses',
    }

    if (updatedFilters.categories.includes(category)) {
      const id = updatedFilters.categories.indexOf(category)
      updatedFilters.categories.splice(id, 1)
    } else {
      updatedFilters.categories.push(category)
    }

    setFilters(updatedFilters)
    context.setFilters(updatedFilters)
  }

  // helper function called whenever any filter needs an update
  const filterChange = (key, value) => {
    if (initialized) {
      const updatedFilters = {
        ...filters,
        [key]: value,
      }

      if (key === 'type') {
        updatedFilters.page = 1

        if (value === '') {
          updatedFilters.categories = []
        }
      }

      if (updatedFilters.type !== 'Events') {
        updatedFilters.dateStart = ''
        updatedFilters.dateEnd = ''
      }

      setFilters(updatedFilters)
      context.setFilters(updatedFilters)
    } else {
      setInitialized(true)
    }
  }

  // Change dates dropdown label
  const datesChange = (label) => {
    setDatesLabel(label)
  }

  // Applys the custom date range selection
  const applyDateFilter = () => {
    let updatedFilters = { ...filters }

    updatedFilters = {
      ...filters,
      dateStart: customStart.toISOString(),
      dateEnd: customEnd.toISOString(),
    }

    setFilters(updatedFilters)
    context.setFilters(updatedFilters)
  }

  return (
    <div className='explore__filters'>
      <div className='explore__header'>
        <h1>Explore</h1>

        <div className='explore__search'>
          <input
            name='query'
            type='text'
            placeholder='Search'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />

          <img src={search} />
        </div>
      </div>

      <h5 className='filters-label'>Filter by:</h5>

      {filters.type !== 'Events' && (
        <div className='explore__toggles'>
          <label>Category</label>
          <button
            className={`filter-toggle ${accordions.category ? 'open' : ''}`}
            onClick={() =>
              setAccordions({ ...accordions, category: !accordions.category })
            }
          >
            Category <DownArrow />
          </button>

          <div
            className={`explore__toggles__group ${
              accordions.category ? 'open' : ''
            }`}
          >
            {context.categories.map((category) => (
              <button
                key={category}
                className={
                  filters.categories.includes(category) ? 'active' : ''
                }
                onClick={() => categorySelect(category)}
              >
                {category}
                {filters.categories.includes(category) && (
                  <img src={selected} />
                )}
              </button>
            ))}
          </div>
        </div>
      )}

      <div className='explore__toggles explore__toggles--type'>
        <div className='explore__filter'>
          <label>Type</label>
          <button
            className={`filter-toggle ${accordions.type ? 'open' : ''}`}
            onClick={() =>
              setAccordions({ ...accordions, type: !accordions.type })
            }
          >
            Type <DownArrow />
          </button>

          <div
            className={`explore__toggles__group ${
              accordions.type ? 'open' : ''
            }`}
          >
            <div className='segmented-controller'>
              <button
                onClick={() => filterChange('type', 'Businesses')}
                className={filters.type === 'Businesses' ? 'active' : ''}
              >
                Businesses
              </button>

              <button
                onClick={() => filterChange('type', 'Events')}
                className={filters.type === 'Events' ? 'active' : ''}
              >
                Events
              </button>

              <button
                onClick={() => filterChange('type', '')}
                className={filters.type === '' ? 'active' : ''}
              >
                All
              </button>
            </div>
          </div>
        </div>

        {context.filters.type === 'Events' && (
          <div className='explore__filter'>
            <label>Dates</label>

            <div className='date-dropdown'>
              <button
                className={datesOpen ? 'open' : ''}
                onClick={() => setDatesOpen(!datesOpen)}
              >
                {datesLabel}
                <img src={drop} />
              </button>

              {datesOpen && (
                <div className='date-dropdown__options'>
                  <fieldset>
                    {[
                      'All',
                      'This Week',
                      'Next Week',
                      'Next Month',
                      'Next Three Months',
                      'Custom',
                    ].map((label) => (
                      <label key={label}>
                        <input
                          type='radio'
                          name='dates'
                          value={label}
                          checked={datesLabel === label}
                          onChange={() => datesChange(label)}
                        />
                        <span>{label}</span>
                      </label>
                    ))}
                  </fieldset>

                  {datesLabel === 'Custom' && (
                    <>
                      <div className='date-dropdown__custom'>
                        <label>
                          From
                          <SingleDatePicker
                            id='start'
                            onDateChange={(date) => setCustomStart(date)}
                            date={customStart}
                            focused={focus.start}
                            onFocusChange={({ focused }) =>
                              setFocus({ ...focus, start: focused })
                            }
                            withPortal={true}
                          />
                        </label>

                        <label>
                          To
                          <SingleDatePicker
                            id='end'
                            onDateChange={(date) => setCustomEnd(date)}
                            date={customEnd}
                            focused={focus.end}
                            onFocusChange={({ focused }) =>
                              setFocus({ ...focus, end: focused })
                            }
                            withPortal={true}
                          />
                        </label>

                        {customStart && customEnd && (
                          <>
                            {customStart.isAfter(customEnd) && (
                              <p>
                                Please make sure "To" date comes after "From"
                                date.
                              </p>
                            )}
                          </>
                        )}

                        <button
                          disabled={
                            datesLabel === 'Custom' &&
                            (!customStart ||
                              !customEnd ||
                              customStart.isAfter(customEnd))
                          }
                          onClick={applyDateFilter}
                        >
                          Apply
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='explore__search'>
        <input
          name='query'
          type='text'
          placeholder='Search'
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />

        <img src={search} />
      </div>
    </div>
  )
}

export default Filters
