import React from 'react'

export default React.createContext({
  categories: [],
  filters: {
    categories: [],
    type: '',
    query: '',
    dateStart: '',
    dateEnd: '',
    page: 1,
  },
  results: {
    pageCount: 0,
  },
  getCategories: () => {},
  getResults: () => {},
  setFilters: (filters) => {},
})
